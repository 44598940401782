<template>
  <div>
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" color="error" top>
      {{ snackbarText }}
      <v-btn text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-overlay :value="loading">
      <v-progress-circular
          indeterminate
          size="64"
          color="primary"
      />
    </v-overlay>
<!-- ____________________________________________________________________________ 情報表示 -->
      <div v-if="mode==''">

      <v-btn
          class="ma-2"
          tile
          outlined
          color="#e60044"
          @click="mode='edit'"
      >
        <v-icon left>mdi-pencil</v-icon> 情報の修正
      </v-btn>

      <v-card
          class="mx-auto mb-5"
          outlined
      >
        <v-card-title>お客様情報</v-card-title>
        <v-card-text>


          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>お名前</v-list-item-title>
              <v-list-item-subtitle>{{member.name}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>ふりがな</v-list-item-title>
              <v-list-item-subtitle>{{member.furigana}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>生年月日</v-list-item-title>
              <v-list-item-subtitle>{{member.birthday}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>性別</v-list-item-title>
              <v-list-item-subtitle>{{member.sex}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>郵便番号</v-list-item-title>
              <v-list-item-subtitle>{{member.zip}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>都道府県名</v-list-item-title>
              <v-list-item-subtitle>{{member.city}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>市区町村</v-list-item-title>
              <v-list-item-subtitle>{{member.address1}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>以下住所</v-list-item-title>
              <v-list-item-subtitle>{{member.address2}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>電話番号</v-list-item-title>
              <v-list-item-subtitle>{{member.tel}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

        </v-card-text>
      </v-card>

      <v-card
          class="mx-auto mb-5"
          outlined
      >
        <v-card-title>食べ物に関して</v-card-title>
        <v-card-text>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>アレルギー</v-list-item-title>
              <v-list-item-subtitle>{{member.allergy}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>好きな食べ物</v-list-item-title>
              <v-list-item-subtitle>{{member.favorite_food}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>嫌いな食べ物</v-list-item-title>
              <v-list-item-subtitle>{{member.disliked_food}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>よく飲むお酒</v-list-item-title>
              <v-list-item-subtitle>{{member.alcohol_note}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

        </v-card-text>
      </v-card>

      <v-card
          class="mx-auto mb-5"
          outlined
      >
        <v-card-title>お店に関して</v-card-title>
        <v-card-text>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>好きな飲食店</v-list-item-title>
              <v-list-item-subtitle class="newLine" >{{member.favorite_genre | arrayNewLine}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>よく行くお店</v-list-item-title>
              <v-list-item-subtitle>{{member.favorite_shop}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>利用目的</v-list-item-title>
              <v-list-item-subtitle class="newLine">{{member.purpose | arrayNewLine}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>外食時の平均利用金額</v-list-item-title>
              <v-list-item-subtitle >{{member.average_amount}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

        </v-card-text>
      </v-card>
      </div>
<!-- ____________________________________________________________________________ 編集 -->
      <div v-else-if="mode=='edit'">

        <v-btn
            class="ma-2"
            color="#e60044"
            text
            @click="editCancel"
        >
          ←キャンセル
        </v-btn>

        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >

          <v-card
              class="mx-auto mb-5"
              outlined
          >
            <v-card-title>お客様情報</v-card-title>
            <v-card-text>
              <v-text-field
                  v-model="member.name"
                  label="お名前"
                  :rules="nameRules"
                  required
              ></v-text-field>

              <v-text-field
                  v-model="member.furigana"
                  label="ふりがな"
                  :rules="furiganaRules"
                  required
              ></v-text-field>

              <v-menu
                  ref="birthdayMenu"
                  v-model="birthdayMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
              >

                <template v-slot:activator="{ on }">
                  <v-text-field
                      v-model="member.birthday"
                      :rules="birthdayRules"
                      label="生年月日"
                      prepend-icon="fas fa-birthday-cake"
                      readonly
                      v-on="on"

                  ></v-text-field>
                </template>
                <v-date-picker
                    ref="picker"
                    v-model="member.birthday"
                    :max="new Date().toISOString().substr(0, 10)"
                    min="1950-01-01"
                    locale="ja"
                    @change="save"
                ></v-date-picker>
              </v-menu>

              <v-select
                  v-model="member.sex"
                  :items="sexs"
                  :rules="sexRules"
                  label="性別"
                  required
              ></v-select>

              <v-text-field
                  v-model="member.zip"
                  :rules="zipRules"
                  label="郵便番号"
                  required
                  @blur="fetchAddress(member.zip)"
                  placeholder="1010021"
              ></v-text-field>

              <v-select
                  v-model="member.city"
                  :items="citys"
                  :rules="cityRules"
                  label="都道府県名"
                  required
              ></v-select>

              <v-text-field
                  v-model="member.address1"
                  :rules="address1Rules"
                  label="市区町村"
                  required
              ></v-text-field>

              <v-text-field
                  v-model="member.address2"
                  :rules="address2Rules"
                  label="以下住所"
                  required
              ></v-text-field>

              <v-text-field
                  v-model="member.tel"
                  :rules="telRules"
                  label="電話番号"
                  placeholder="090-0000-0000"
                  required
              ></v-text-field>

            </v-card-text>
          </v-card>

          <v-card
              class="mx-auto mb-5"
              outlined
          >
            <v-card-title>食べ物に関して</v-card-title>
            <v-card-text>
              <v-textarea
                  outlined
                  v-model="member.allergy"
                  label="アレルギー"
                  placeholder="えび、かに、小麦、そば、卵、乳、落花生"
                  value=""
              ></v-textarea>

              <v-textarea
                  outlined
                  v-model="member.favorite_food"
                  label="好きな食べ物"
                  placeholder="まぐろ"
                  value=""
              ></v-textarea>

              <v-textarea
                  outlined
                  v-model="member.disliked_food"
                  label="嫌いな食べ物"
                  placeholder="セロリ、パクチー"
                  value=""
              ></v-textarea>

              <v-textarea
                  outlined
                  v-model="member.alcohol_note"
                  label="よく飲むお酒"
                  placeholder="日本酒,ワイン"
                  value=""
              ></v-textarea>
            </v-card-text>
          </v-card>

          <v-card
              class="mx-auto"
              outlined
          >
            <v-card-title>お店に関して</v-card-title>
            <v-card-text>
              <v-select
                  v-model="member.favorite_genre"
                  :items="favorite_genres"
                  attach
                  chips
                  label="好きな飲食店"
                  multiple
              ></v-select>
              <v-divider class="mx-4 mb-5"></v-divider>
              <v-textarea
                  outlined
                  v-model="member.favorite_shop"
                  label="よく行くお店"
                  placeholder="渋谷のxxxx"
                  value=""
              ></v-textarea>

              <v-select
                  v-model="member.purpose"
                  :items="purposes"
                  attach
                  chips
                  label="利用目的"
                  multiple
              ></v-select>
              <v-divider class="mx-4 mb-5"></v-divider>
              <v-select
                  v-model="member.average_amount"
                  :items="average_amounts"
                  item-text="label"
                  item-value="value"
                  label="外食時の平均利用金額"
                  return-object
              ></v-select>

            </v-card-text>
          </v-card>


          <div align="center" class="ma-5">
            <v-btn
                color="#e60044"
                dark
                depressed
                tile
                x-large
                @click="goConfirm"
            >
              確認する
            </v-btn>
          </div>

        </v-form>
    </div>
<!-- ____________________________________________________________________________ 確認 -->
      <div v-else-if="mode=='confirm'">
        <div>下記の内容で登録しますか？</div>
        <div align="center" class="ma-5">
          <v-btn
              color="#e60044"
              class="ma-2"
              dark
              depressed
              tile
              x-large
              @click="goEdit"
          >
            前に戻る
          </v-btn>
          <v-btn
              color="#e60044"
              class="ma-2"
              dark
              depressed
              tile
              x-large
              @click="goUpdate"
          >
            登録
          </v-btn>
        </div>
        <v-card
            class="mx-auto mb-5"
            outlined
        >
          <v-card-title>お客様情報</v-card-title>
          <v-card-text>

            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>お名前</v-list-item-title>
                <v-list-item-subtitle>{{member.name}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>ふりがな</v-list-item-title>
                <v-list-item-subtitle>{{member.furigana}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>生年月日</v-list-item-title>
                <v-list-item-subtitle>{{member.birthday}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>性別</v-list-item-title>
                <v-list-item-subtitle>{{member.sex}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>郵便番号</v-list-item-title>
                <v-list-item-subtitle>{{member.zip}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>都道府県名</v-list-item-title>
                <v-list-item-subtitle>{{member.city}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>市区町村</v-list-item-title>
                <v-list-item-subtitle>{{member.address1}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>以下住所</v-list-item-title>
                <v-list-item-subtitle>{{member.address2}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>電話番号</v-list-item-title>
                <v-list-item-subtitle>{{member.tel}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

          </v-card-text>
        </v-card>

        <v-card
            class="mx-auto mb-5"
            outlined
        >
          <v-card-title>食べ物に関して</v-card-title>
          <v-card-text>

            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>アレルギー</v-list-item-title>
                <v-list-item-subtitle>{{member.allergy}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>好きな食べ物</v-list-item-title>
                <v-list-item-subtitle>{{member.favorite_food}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>嫌いな食べ物</v-list-item-title>
                <v-list-item-subtitle>{{member.disliked_food}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>よく飲むお酒</v-list-item-title>
                <v-list-item-subtitle>{{member.alcohol_note}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

          </v-card-text>
        </v-card>

        <v-card
            class="mx-auto mb-5"
            outlined
        >
          <v-card-title>お店に関して</v-card-title>
          <v-card-text>

            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>好きな飲食店</v-list-item-title>
                <v-list-item-subtitle class="newLine">{{member.favorite_genre | arrayNewLine}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>よく行くお店</v-list-item-title>
                <v-list-item-subtitle>{{member.favorite_shop}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>利用目的</v-list-item-title>
                <v-list-item-subtitle class="newLine">{{member.purpose | arrayNewLine}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>外食時の平均利用金額</v-list-item-title>
                <v-list-item-subtitle v-if="member.average_amount.label">{{member.average_amount.label}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

          </v-card-text>
        </v-card>
        <div align="center" class="ma-5">
          <v-btn
              color="#e60044"
              class="ma-2"
              dark
              depressed
              tile
              x-large
              @click="goEdit"
          >
            前に戻る
          </v-btn>
          <v-btn
              color="#e60044"
              class="ma-2"
              dark
              depressed
              tile
              x-large
              @click="goUpdate"
          >
            登録
          </v-btn>
        </div>
      </div>
    </div>


</template>

<script>
import axiosJsonpAdapter from 'axios-jsonp'
let url 		= process.env.VUE_APP_API_ORIGIN+"/api_register/api";
const ZIPCODE_API_URL = 'https://zipcloud.ibsnet.co.jp/api/search'
import member from "@/api/members.js";
import axios from "axios";

export default {
  data() {
    return {
      axios,
      url,
      valid: false,
      loading:true,
      mode:'',

      // スネーク設定
      snackbar: false, //snackbar設定
      snackbarText: "", //	表示テキスト
      snackbarTimeout: 3000, //	タイムアウト秒数

      /** ________________________________________ 項目設定 **/
      member:[],
      // お客様情報
      nameRules: [
        v => !!v || 'お名前を入力してください',
      ],
      // ふりがな
      furiganaRules: [
        v => !!v || 'ふりがなを入力してください',
        v => /^[あ-ん゛゜ぁ-ぉゃ-ょー「」、]+/.test(v) || 'ふりがなを「ひらがな」でご入力ください。',
      ],
      // 生年月日
      birthdayMenu: false,
      birthdayRules: [
        v => !!v || '生年月日を入力してください',
      ],
      // 性別
      sexRules: [
        v => !!v || '性別を選択してください',
      ],
      sexs:['男性','女性'],
      // 郵便番号
      zipRules: [
        v => /^\d{1,7}$/.test(v) || '郵便番号を「半角数字」7桁でご入力ください。',
        v => !!v || '郵便番号を入力してください',
      ],
      // 都道府県名
      citys: ['北海道','青森県','岩手県','宮城県','秋田県','山形県','福島県','茨城県','栃木県','群馬県','埼玉県','千葉県','東京都','神奈川県','新潟県','富山県','石川県','福井県','山梨県','長野県','岐阜県','静岡県','愛知県','三重県','滋賀県','京都府','大阪府','兵庫県','奈良県','和歌山県','鳥取県','島根県','岡山県','広島県','山口県','徳島県','香川県','愛媛県','高知県','福岡県','佐賀県','長崎県','熊本県','大分県','宮崎県','鹿児島県','沖縄県'],
      cityRules: [
        v => !!v || '都道府県名を選択してください',
      ],
      // 市区町村
      address1Rules: [
        v => !!v || '市区町村を入力してください',
      ],
      // 以下住所
      address2Rules: [
        v => !!v || '以下住所を入力してください',
      ],
      // 電話番号
      telRules: [
        v => !!v || '電話番号を入力してください',
        v => /^\d{1,4}-\d{1,4}-\d{1,4}$/.test(v) || '電話番号を「半角数字」10～11桁でご入力ください。',
        v => v != undefined && v.length <= 14 || '電話番号を「半角数字」10～11桁でご入力ください。',
      ],

      //お店に関して
      favorite_genres:['焼き肉','寿司','居酒屋','イタリアン','ラーメン','カフェ'],
      purposes:['デート','接待','会食','記念日','友人と'],
      average_amounts:[
        { label: '5,000円～', value: '5000'},
        { label: '10,000円～', value: '10000'},
        { label: '15,000円～', value: '15000'},
        { label: '20,000円～', value: '20000'},
      ],


    }
  },
  watch: {
    birthdayMenu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
  },
  created() {

    // 会員情報の取得
    member.fetch(this.memberInfo, this.$store.state.token);
  },
  methods: {
    /** __________________________________________________________________________ 会員情報の取得 */
    async memberInfo(info) {

      if(!info.data[0]) {
        this.$store.commit("SET_TOKEN", null);
        this.$router.push("/login");
      }

      this.member = info.data[0];
      this.$nextTick(()=>{
        this.loading = false

        //ログイン画面にリダイレクト
        if(!this.member.no) {
          this.$store.commit("SET_TOKEN", null);
          this.$router.push("/login");
        }
      })

    },
    /** __________________________________________________ **/
    editCancel(){
      // 会員情報の取得
      member.fetch(this.memberInfo, this.$store.state.token);

      this.$nextTick(()=>{
        this.mode = ''
      })
    },
    /** __________________________________________________誕生日の一時保存 **/
    save (value) {
      this.$refs.birthdayMenu.save(value)
    },
    /** __________________________________________________ 郵便番号処理 **/
    async fetchAddress(zip) {

      // 郵便番号のバリデーションチェック
      const reg = /^\d{7}$/
      if (!reg.test(zip)) return

      // 住所apiを叩く
      const res = await axios.get(ZIPCODE_API_URL, {
        adapter: axiosJsonpAdapter,
        params: {
          zipcode: zip
        }
      })

      // 存在しない郵便番号でapiを叩くと200以外のステータスが返ってくる
      if (res.status !== 200) return

      // 返却されたデータを挿入する
      this.member.city 		  = res.data.results[0].address1
      this.member.address1 	= res.data.results[0].address2 + res.data.results[0].address3
    },
    /** __________________________________________________ 確認画面を表示 **/
    goConfirm () {
      if (this.$refs.form.validate()) {

        this.mode = "confirm"
        window.scrollTo({
          top: 0,
        });

      //バリデーションエラー
      }else {
        window.scrollTo({
          top: 0,
        });
      }
    },
    /** __________________________________________________ 確認画面から戻る **/
    goEdit () {
      this.mode = 'edit'
      window.scrollTo({
        top: 0,
      });
    },
    /** __________________________________________________ 登録処理 **/
    goUpdate () {


      // パラメータ設定
      let params = new URLSearchParams();
      params.append("no", this.member.no);
      params.append("token", this.$store.state.token);

      params.append("memberType", "2");                       // 会員種別
      params.append("name1", this.member.name);                     // お名前
      params.append("furigana1", this.member.furigana);             // ふりがな
      params.append("birthday", this.member.birthday);              // 生年月日
      params.append("sex", this.member.sex);                        // 性別
      params.append("zip", this.member.zip);                        // 郵便番号
      params.append("city", this.member.city);                      // 都道府県名
      params.append("address1", this.member.address1);              // 市区町村
      params.append("address2", this.member.address2);              // 以下住所
      params.append("tel", this.member.tel);                        // 電話番号
      params.append("allergy", this.member.allergy);                // アレルギー
      params.append("favorite_food", this.member.favorite_food);    // 好きな食べ物
      params.append("disliked_food", this.member.disliked_food);    // 嫌いな食べ物
      params.append("alcohol_note", this.member.alcohol_note);      // よく飲むお酒
      params.append("favorite_genre", this.member.favorite_genre);  // 好きな飲食店
      params.append("favorite_shop", this.member.favorite_shop);    // よく行くお店
      params.append("purpose", this.member.purpose);                // 利用目的
      params.append("average_amount", this.member.average_amount);  // 外食時の平均利用金額

      // update処理
      member.update(this.updateResult, params);

    },
    updateResult (res) {

      this.result = res.data.result;

      // エラー処理
      if (!this.result || (this.result == "error")) {
        switch (res.data.errorCode) {
          default:
            this.snackbarText = "エラー：問題が発生しました";
            this.mode = "edit"
        }
        this.snackbar = true;
        stop();
      }

      // 更新成功
      if (this.result == "success") {

        // 会員情報の取得
        member.fetch(this.memberInfo, this.$store.state.token);

        // snackbar表示
        this.snackbarText = "情報を更新しました";
        this.snackbar = true;
        this.mode = ""


      }

    },

  }

}
</script>
<style scoped>

</style>
