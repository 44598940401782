<template>
  <div>
      <v-card
          class="mx-auto mb-5"
          outlined
      >
		<v-card-text>
		配送先の登録・確認・変更ができます。<br>
		新たに配送先を登録する場合は、下記の［配送先を新規に登録する］からご入力ください。
		</v-card-text>
		<v-card-text class="pt-0">
			<v-btn tile outlined color="#e60044">
			<v-icon left>mdi-pencil</v-icon> 配送先を新規に登録する
			</v-btn>
		</v-card-text>
	</v-card>
	<v-card
      class="mx-auto mb-5"
      outlined
  >
		<v-card-title>配送先リスト</v-card-title>
        <v-card-text>
		<v-container class="pa-0" fluid>
			<v-row align="center" justify="start">
				<v-col sm="12" md="12" lg="5" xl="7" class="pt-0">
				てすと汰朗<br>
				東京都千代田区<br>
				外神田1-1-11111<br>
				12345678901<br>
				test@test.com<br>
				</v-col>
				<v-col sm="12" md="12" lg="7" xl="5" class="pt-0">
					<v-btn class="ma-2" tile outlined color="#e60044">
					<v-icon left>mdi-pencil</v-icon> 編集する
					</v-btn>
					<v-btn class="ma-2" tile outlined color="#e60044">
					<v-icon left>mdi-close</v-icon> 削除する
					</v-btn>
				</v-col>
			</v-row>
		</v-container>
		</v-card-text>
		</v-card>


    </div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>
