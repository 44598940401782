<template>
<div>
	<v-card
	class="mx-auto mb-5"
	outlined
	>
		<v-card-title>注文日時：2020年10月10日</v-card-title>
		<v-card-text>
			<v-row align="center" justify="start" class="btn-register">
				<v-col sm="3" md="3" lg="3" xl="3" class="pt-0">
				商品名
				</v-col>
				<v-col sm="9" md="9" lg="9" xl="9" class="pt-0 pl-0">
				商品タイトルタイトル
				</v-col>
			</v-row>
			<v-row align="center" justify="start" class="btn-register">
				<v-col sm="3" md="3" lg="3" xl="3" class="pt-0">
				注文No.
				</v-col>
				<v-col sm="9" md="9" lg="9" xl="9" class="pt-0 pl-0">
				001010000000
				</v-col>
			</v-row>
			<v-row align="center" justify="start" class="btn-register">
				<v-col sm="3" md="3" lg="3" xl="3" class="pt-0">
				注文金額
				</v-col>
				<v-col sm="9" md="9" lg="9" xl="9" class="pt-0 pl-0">
				11,200円(税込)
				</v-col>
			</v-row>
			<v-row align="center" justify="start" class="btn-register">
				<v-col sm="3" md="3" lg="3" xl="3" class="pt-0">
				ステータス
				</v-col>
				<v-col sm="9" md="9" lg="9" xl="9" class="pt-0 pl-0">
				注文受付済
				</v-col>
			</v-row>				
		</v-card-text>
	</v-card>
	<v-card
	class="mx-auto mb-5"
	outlined
	>
		<v-card-title>注文日時：2020年10月10日</v-card-title>
		<v-card-text>
			<v-row align="center" justify="start" class="btn-register">
				<v-col sm="3" md="3" lg="3" xl="3" class="pt-0">
				商品名
				</v-col>
				<v-col sm="9" md="9" lg="9" xl="9" class="pt-0 pl-0">
				商品タイトルタイトル
				</v-col>
			</v-row>
			<v-row align="center" justify="start" class="btn-register">
				<v-col sm="3" md="3" lg="3" xl="3" class="pt-0">
				注文No.
				</v-col>
				<v-col sm="9" md="9" lg="9" xl="9" class="pt-0 pl-0">
				001010000000
				</v-col>
			</v-row>
			<v-row align="center" justify="start" class="btn-register">
				<v-col sm="3" md="3" lg="3" xl="3" class="pt-0">
				注文金額
				</v-col>
				<v-col sm="9" md="9" lg="9" xl="9" class="pt-0 pl-0">
				11,200円(税込)
				</v-col>
			</v-row>
			<v-row align="center" justify="start" class="btn-register">
				<v-col sm="3" md="3" lg="3" xl="3" class="pt-0">
				ステータス
				</v-col>
				<v-col sm="9" md="9" lg="9" xl="9" class="pt-0 pl-0">
				注文受付済
				</v-col>
			</v-row>				
		</v-card-text>
	</v-card>
	<div class="text-center">
    <v-pagination
      v-model="page"
      :length="4"
      circle
    ></v-pagination>
  </div>
</div>
</template>

<script>
export default {
name: "order_history",
data () {
	return {
		page: 1,
	}
},
}
</script>

<style scoped>

</style>
