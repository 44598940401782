<template>
	<div>
		<v-snackbar v-model="snackbar" :timeout="snackbarTimeout" color="error" top>
			{{ snackbarText }}
			<v-btn text @click="snackbar = false">Close</v-btn>
		</v-snackbar>
		<v-container class="pa-0" fluid>
			<v-row justify="center">
				<v-col cols="12" xs="12" sm="12" md="10" lg="8" xl="8">
					<v-layout text-left wrap>
						<v-row  justify="center" class="pa-5 align-start">
							<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
								<h2>マイページ</h2>
							</v-col>
							<v-col cols="12" xs="12" sm="12" md="10" lg="4" xl="4">
								<v-card
										class="mx-auto"
										max-width="300"
                    outlined
								>
									<v-list flat>
										<v-subheader>メニュー</v-subheader>
										<v-list-item-group v-model="menu" color="primary">
											<v-list-item
													v-for="(menuItem, i) in menus"
													:key="i"
													@click="selectedMenu(menuItem.value)"
                          :disabled="menu==i"
											>
												<v-list-item-icon>
													<v-icon v-text="menuItem.icon"></v-icon>
												</v-list-item-icon>
												<v-list-item-content>
													<v-list-item-title v-text="menuItem.text"></v-list-item-title>
												</v-list-item-content>
											</v-list-item>
										</v-list-item-group>
									</v-list>
								</v-card>
							</v-col>

              <v-col cols="12" xs="12" sm="12" md="12" lg="8" xl="8">
                <profile v-if="(mode=='profile' || mode=='' || mode==undefined )" />
                <reservation_history  v-if="(mode=='reservation_history')" />
                <order_history  v-if="(mode=='order_history')" />
                <password_reset  v-if="(mode=='password_reset')" />
                <shipping_addresses  v-if="(mode=='shipping_addresses')" />
              </v-col>

            </v-row>
          </v-layout>
        </v-col>
      </v-row>
    </v-container>

	</div>
</template>

<script>
	import profile from "./components/profile"
	import reservation_history from "./components/reservation_history"
	import order_history from "./components/order_history"
	import shipping_addresses from "./components/shipping_addresses"
	import password_reset from "./components/password_reset"


  export default {
    name:"mypage",
    components:{
      profile,
      reservation_history,
      order_history,
      shipping_addresses,
      password_reset,
    },
    props: {
      modePara:String
    },
    data() {
      return {

        // スネーク設定
        snackbar: false, 		// snackbar設定
        snackbarText: "", 		// 表示テキスト
        snackbarTimeout: 3000, 	// タイムアウト秒数

        // メニュー
        menu: 0,
        menus: [
          {value: 'profile',              text: '会員情報',     icon: 'mdi-account'},
          {value: 'reservation_history',  text: 'ご予約情報',    icon: 'mdi-clock'},
          /*{value: 'order_history',        text: 'ご注文履歴',    icon: 'mdi-cart'},*/
          /*{value: 'shipping_addresses',   text: '配送先設定',    icon: 'mdi-map-marker'},*/
          /*{value: 'password_reset',       text: 'パスワード設定', icon: 'mdi-key'},*/
          {value: 'logout',               text: 'ログアウト',    icon: 'mdi-logout'},
        ],

        // loading
        loading:true,

        mode:'',


      }
    },
    created() {

      this.mode = this.modePara

      // トークンチェック
      if(!this.$store.state.token) {
        this.$router.push("/login");
      }

      /** __________________________________________________________________________ メニュー選択表示の変更 */
        switch(this.modePara) {
            // 会員情報
          case 'profile': case '': default: case undefined:
            this.menu= 0
            break;

            // 予約情報
          case 'reservation_history':
            this.menu = 1
            break;

            // ご注文履歴
          case 'order_history':
            this.menu = 2
            break;

            // 配送先設定
          case 'shipping_addresses':
            this.menu = 3
            break;

            // パスワード設定
          case 'password_reset':
            this.menu = 4
            break;
        }

    },
    methods: {
      /** __________________________________________________________________________ メニュー切り替え */
      selectedMenu(value){
        switch (value) {

          // 会員情報
          case 'profile': case '': default:
            this.mode = 'profile'
            this.$router.push("/mypage");
            break;

          // ご予約情報
          case 'reservation_history':
            this.mode = 'reservation_history'
            this.$router.push("/mypage/reservation_history");
            break;

          // ご注文履歴
          case 'order_history':
            this.mode = 'order_history'
            this.$router.push("/mypage/order_history");
            break;

          // 配送先設定
          case 'shipping_addresses':
            this.mode = 'shipping_addresses'
            this.$router.push("/mypage/shipping_addresses");
            break;

          // パスワード設定
          case 'password_reset':
            this.mode = 'password_reset'
            this.$router.push("/mypage/password_reset");
            break;

          // ログアウト
          case 'logout':
            // tokenを空にする
            this.$store.commit("SET_TOKEN", null);

            // 画面の切り替え
            this.$router.push("/login");
            break;
        }
      },

    },


  }
</script>
<style scoped>

</style>
