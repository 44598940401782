<template>
	<div>
    <v-overlay :value="loading">
      <v-progress-circular
          indeterminate
          size="64"
          color="primary"
      />
    </v-overlay>
        <v-tabs
          v-model="tab"
          align-with-title
          class="mb-4"
        >
          <v-tabs-slider></v-tabs-slider>

          <v-tab
            v-for="item in items"
            :key="item"
            class="ml-0"
          >
            {{ item }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
      <v-tab-item
      >
      <div v-if="!reservers" align="center">
        ご予約はありません
      </div>
      <v-card
          class="mx-auto mb-5"
          outlined
          v-for="reserve in reservers"
          :key="reserve.no"
      >
        <v-card-title>{{reserve.reservation_date | japaneseDate}}</v-card-title>
        <v-card-text align="center" v-if="reserve.cancel">キャンセル済み（{{ reserve.cancel_on}}）</v-card-text>
        <v-card-text>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>ご予約番号</v-list-item-title>
              <v-list-item-subtitle >{{reserve.no}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>ご予約店舗</v-list-item-title>
              <v-list-item-subtitle >{{reserve.shop_name}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>時間</v-list-item-title>
              <v-list-item-subtitle>{{reserve.reservation_time}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>人数</v-list-item-title>
              <v-list-item-subtitle>{{reserve.people}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>コース</v-list-item-title>
              <v-list-item-subtitle>{{reserve.reservation_course}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>特記事項</v-list-item-title>
              <v-list-item-subtitle>{{reserve.note}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-card-text v-if="!reserve.cancel" align="right">
            <a @click.stop="openCancelDialog(reserve)" style="text-decoration: underline">
              キャンセル
            </a>
          </v-card-text>

        </v-card-text>
      </v-card>





		<!-- _______________________________________ キャンセルdialog -->
		<v-dialog
				v-model="cancelDialog"
				max-width="600"
		>
			<v-card>
				<v-card-title class="headline">ご予約をキャンセルしますか？</v-card-title>

				<v-card-text>
					キャンセルするとキャンセル料が発生します<br/>
					<br/>
					3日前からのキャンセル：50%<br/>
					前日からのキャンセル：100%
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>

					<v-btn
							color="#e60044"
							text
							@click="closeCancelDialog()"
					>
						キャンセルしない
					</v-btn>

					<v-btn
							color="#e60044"
							text
							@click="cancel(cancelDialogItem.no)"
					>
						キャンセルする
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-tab-item>

	<v-tab-item>
<v-card
          class="mx-auto mb-5"
          outlined
          v-for="reserve in reservers"
          :key="reserve.no"
      >
        <v-card-title>{{reserve.reservation_date | japaneseDate}}</v-card-title>
        <v-card-text align="center" v-if="reserve.cancel">キャンセル済み（{{ reserve.cancel_on}}）</v-card-text>
        <v-card-text>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>ご予約番号</v-list-item-title>
              <v-list-item-subtitle >{{reserve.no}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>ご予約店舗</v-list-item-title>
              <v-list-item-subtitle >{{reserve.shop_name}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>時間</v-list-item-title>
              <v-list-item-subtitle>{{reserve.reservation_time}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>人数</v-list-item-title>
              <v-list-item-subtitle>{{reserve.people}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>コース</v-list-item-title>
              <v-list-item-subtitle>{{reserve.reservation_course}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>特記事項</v-list-item-title>
              <v-list-item-subtitle>{{reserve.note}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-card-text v-if="!reserve.cancel" align="right">
            <a @click.stop="openCancelDialog(reserve)" style="text-decoration: underline">
              キャンセル
            </a>
          </v-card-text>

        </v-card-text>
      </v-card>
	</v-tab-item>
    </v-tabs-items>
	</div>
</template>

<script>
	import reserver from "@/api/reserver.js";


	export default {
		data() {
		return {
		tab: null,
        items: [
        'ご来店予約',
          //'過去のご予約',
        ],
        loading:true,

				// 予約情報
				reservers:[],
				reserverQuery:{
					token:this.$store.state.token
				},

				// キャンセルダイアログ
				cancelDialog: false,
				cancelDialogItem: [],

			}

		},
		created() {
      reserver.fetchList(this.reserverInfo, this.reserverQuery);
		},
		methods: {
			/** __________________________________________________________________________ 予約情報の取得 */
			async reserverInfo(info) {
				this.reservers = info.data;

				this.$nextTick(()=>{
          this.loading = false
        })

			},

			/** __________________________________________________________________________ キャンセルdialog */
			// Open
			openCancelDialog(item){
				this.cancelDialog = true
				this.cancelDialogItem = item
			},
			// Close
			closeCancelDialog(){
				this.cancelDialog = false
				this.cancelDialogItem = []
			},
			/** __________________________________________________________________________ キャンセル処理 */
			cancel (num){

				// パラメータ設定
				let params = new URLSearchParams();
				params.append("no", num);
				params.append("token", this.$store.state.token);

				// キャンセル処理
				reserver.cancel(this.reserverCancel, params);
			},
			reserverCancel(res){

				this.result = res.data.result;

				// エラー処理
				if (!this.result || (this.result == "error")) {
					switch (res.data.errorCode) {
						default:
							this.snackbarText = res.data.errorCode;
					}
					this.snackbar = true;
					stop();
				}

				// キャンセル成功
				if (this.result == "success") {

					// 予約情報の取得
					reserver.fetchList(this.reserverInfo, this.reserverQuery);
					this.cancelDialog = false

					// snackbar表示
					this.snackbarText = "ご予約をキャンセルしました";
					this.snackbar = true;
				}


			},



		}

			}
</script>
<style scoped>

</style>
