<template>
  <div>
      <v-card
          class="mx-auto mb-5"
          outlined
      >
        <v-card-title>パスワードの変更</v-card-title>
        <v-card-text>新たなパスワードを設定してください
        </v-card-text>
        <v-card-text>
		<v-container class="pa-0" fluid>
			<v-row align="baseline">
				<v-col sm="12" md="12" lg="3" xl="3" class="pt-0">
パスワード
				</v-col>
				<v-col sm="12" md="12" lg="9" xl="9" class="pt-0">
                    <v-text-field
            outlined
            
          ></v-text-field>

				</v-col>
			</v-row>
			<v-row>
				<v-col sm="12" md="12" lg="12" xl="12" class="pt-0 text-center">
					<v-btn class="ma-2" dark depressed tile color="#e60044">
					確定する
					</v-btn>
				</v-col>
			</v-row>
		</v-container>
		</v-card-text>
		</v-card>


    </div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>
